body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slideimage {
  position: absolute;
  height: 100%;
  max-height:100%;
  object-fit: cover;
}

main {
  flex: 1 0 auto;
  /* min-height: calc(100vh - 100px); */
}

.page-footer {
  padding-top: 0px;
}

.page-footer .footer-copyright {
  /* overflow: hidden; */
  overflow: visible;
  min-height: 10px;
  padding: 5px 0px;
}
